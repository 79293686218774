<template>
    <v-dialog v-model="confirmPopover" width="500" :persistent="true">
        <v-card>
            <v-card-title>
                <h4 style="padding-bottom:10px">
                    Press "Confirm" to delete {{ type }} - {{ name }}
                </h4>
                <div style="margin-bottom:0; font-size:15px;color:orange">
                    <v-icon color="orange" size="20">mdi-alert</v-icon>
                    Please be aware this action can not be reversed
                </div>
            </v-card-title>

            <v-card-actions>
                <v-spacer></v-spacer>
                <!--  -->
                <v-btn color="red" :disabled="loading" @click="$emit('close')" text>
                    Cancel
                </v-btn>
                <v-btn
                    color="green"
                    text
                    :loading="loading"
                    @click="$emit('confirm')"
                >
                    Confirm
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        confirmPopover: false,
        type: null,
        name: null,
        loading: false
    }
};
</script>
